import React from "react";
import logo from "../../assets/Delivairy_Logo_Dark_Transparent.png";

interface LogoProps {
  width?: number;
}

const Logo = ({ width = 100 }: LogoProps) => {
  return (
    <>
      <img src={logo} width={width} alt="Delivairy Logo"></img>
    </>
  );
};

export default Logo;
